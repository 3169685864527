@require './util/color'
@require './util/fonts'
@require './util/mixins'
@require './util/reset'
@require './components/c2a'
@require './components/footer'
@require './components/header'
@require './components/scrollbar'
@require './components/tooltip'
@require './pages/commissions'
@require './pages/gallery'
@require './pages/home'
@require './pages/itg'
@require './pages/portfolio'
@require './pages/resume'

html
    scroll-behavior smooth
body
    background-color dark
    max-width 928px
    margin 0 auto
main
    margin 0 auto
    padding 8px 24px
    min-height calc(100dvh - 93px - 16px - 149px)
    overflow-x clip
    +standard()
        overflow-x visible
h1
    head(64px, lite-head, 600)
    margin-bottom 16px
.h1-small
    head(12.8vw, lite-head, 600)
    margin-bottom 16px
    +tablet()
        head(64px, lite-head, 600)
h2
    head(42px, lite-head, 600)
    margin-bottom 12px
h3
    head(28px, lite-head, 600)
    margin-bottom 4px
.hsub
    axis(22px, lite)
p, li
    text(16px, lite, 400)
    line-height 20px
    text-align justify
    margin-bottom 16px
ul
    list-style-position inside
a
    color accent.blue
    font-weight 600
b
    color lite-head
    font-weight 600
i
    font-style italic
hr
    background-color dark-lite
    border 0
    margin 32px auto 32px
    height 1px
    width 200px

.comment
    color lite-dark
    &-small
        @extends .comment
        font-size 14px
        line-height 16px

.no-bold
    font-weight 400

.diff
    display inline-block
    position relative
    top -8px
    margin-left 4px
    text-align center
    text(14px, lite-head, 600)
    border-radius 4px
    padding 2px 4px
    background-color dark-lite

.action
    display inline-block
    text-align center
    sub(14px, lite-head, 600,, -0.02em)
    border-radius 4px
    padding 4px 12px
    background-color main-dark
    hover-highlight()
    +laptop()
        font-size 16px

.center
    text-align center

.right
    text-align right

.num
    display inline-block
    text-align center
    text(14px, lite, 600,, -0.02em)
    width 32px
    border-radius 4px
    padding 2px 4px
    margin-right 4px
    background-color main-dark

.link
    underline(accent.blue)

.external::after
    content "🡥"
    position relative
    font-size 0.6em
    top -1px
    letter-spacing 0px
    margin-left 3px

.extend
    &-small
        margin-bottom 8px
    &-none
        margin-bottom 0

.container
    margin-bottom 32px  

.crunch
    line-height 10px !important
    margin-bottom 2px !important

.thin
    width 100%
    max-width 820px
    border-radius 4px

@keyframes awes
    0%
        filter hue-rotate(0deg)
    50%
        filter hue-rotate(100deg)
    100%
        filter hue-rotate(0deg)

.link
    text-align justify
    &-epic
        @extend .link
        animation awes 2s infinite

.sig
    margin-top 4px
    text-align right
    svg
        width 225px

.freeze
    overflow-y hidden
    +laptop()
        overflow-y auto

.lowered
    position relative
    z-index -2

.no-zindex
    z-index 1

.column
    flex(column, nowrap, gap: 16px)
