.accent-red {
  color: #ef4444;
}

.accent-orange {
  color: #ffb86c;
}

.accent-yellow {
  color: #fde047;
}

.accent-green {
  color: #4ade80;
}

.accent-cyan {
  color: #67e8f9;
}

.accent-blue {
  color: #60a5fa;
}

.accent-purple {
  color: #855ee8;
}

.accent-pink {
  color: #c084fc;
}

@font-face {
  font-family: Barlow;
  font-style: normal;
  font-weight: 400;
  src: url("Barlow-Regular.38467b6b.woff2");
  font-display: swap;
  font-feature-settings: "case" 1, "ss01" 1, "ss03" 1;
}

@font-face {
  font-family: Barlow;
  font-style: italic;
  font-weight: 400;
  src: url("Barlow-Italic.94958043.woff2");
  font-display: swap;
  font-feature-settings: "case" 1, "ss01" 1, "ss03" 1;
}

@font-face {
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  src: url("Barlow-SemiBold.c752d991.woff2");
  font-display: swap;
  font-feature-settings: "case" 1, "ss01" 1, "ss03" 1;
}

@font-face {
  font-family: Barlow;
  font-style: italic;
  font-weight: 600;
  src: url("Barlow-SemiBoldItalic.175c2ecb.woff2");
  font-display: swap;
  font-feature-settings: "case" 1, "ss01" 1, "ss03" 1;
}

@font-face {
  font-family: Inter Display;
  font-style: normal;
  font-weight: bold;
  src: url("InterDisplay-Bold.65222797.woff2");
  font-display: swap;
  font-feature-settings: "case" 1, "ss01" 1, "ss03" 1;
}

@font-face {
  font-family: Axis;
  font-style: normal;
  font-weight: normal;
  src: url("axis.4980f6f5.woff2");
  font-display: swap;
  font-feature-settings: "case" 1, "ss01" 1, "ss03" 1;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.c2a {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  gap: 16px;
  display: flex;
}

@media screen and (width >= 500px) {
  .c2a {
    flex-flow: row;
    justify-content: space-around;
    align-items: stretch;
    gap: 16px;
    display: flex;
  }
}

@media screen and (width >= 768px) {
  .c2a {
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    display: flex;
  }
}

.c2a-main {
  color: #f2f3f5;
  letter-spacing: -.02em;
  margin-bottom: 0;
  font: bold 36px Inter Display, sans-serif;
}

@media screen and (width >= 768px) {
  .c2a-main {
    font-size: 42px;
  }
}

.c2a-sub {
  color: #f2f3f5;
  letter-spacing: -.01em;
  margin-bottom: 0;
  font: 600 16px Inter Display, sans-serif;
}

@media screen and (width >= 768px) {
  .c2a-sub {
    font-size: 20px;
  }
}

.c2a-icon {
  fill: #f2f3f5;
  width: 96px;
  position: absolute;
  top: -3px;
  right: -3px;
}

@media screen and (width >= 768px) {
  .c2a-icon {
    width: 144px;
    top: -14px;
    right: -14px;
  }
}

.c2a-resume {
  background: linear-gradient(0deg, #855ee8 0% 200%);
  border-radius: 4px;
  flex-flow: column;
  justify-content: flex-end;
  align-items: stretch;
  gap: 0;
  width: 100%;
  height: 140px;
  padding: 16px;
  transition: filter .3s cubic-bezier(.33, 1, .68, 1);
  display: flex;
  position: relative;
}

.c2a-resume:hover, .c2a-resume:focus {
  filter: brightness(115%) saturate(115%);
}

@media screen and (width >= 500px) {
  .c2a-resume {
    width: 320px;
  }
}

@media screen and (width >= 768px) {
  .c2a-resume {
    width: 475px;
    height: 192px;
  }
}

.c2a-actions {
  flex-flow: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 0;
  display: flex;
}

@media screen and (width >= 500px) {
  .c2a-actions {
    flex-flow: column;
    justify-content: space-around;
    align-items: stretch;
    gap: 16px;
    display: flex;
  }
}

@media screen and (width >= 768px) {
  .c2a-actions {
    flex-flow: row;
    justify-content: center;
    align-items: stretch;
    gap: 115px;
    width: 525px;
    display: flex;
  }
}

.c2a-actions .action {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  margin-right: 0;
  display: flex;
}

@media screen and (width >= 500px) {
  .c2a-actions .action {
    height: 30%;
    padding: 4px 16px;
  }
}

@media screen and (width >= 768px) {
  .c2a-actions .action {
    height: 32px;
  }
}

.footer {
  margin-top: 16px;
  padding: 24px;
}

.footer-link {
  color: #b8b8c7;
  letter-spacing: -.01em;
  font: 400 12px Barlow, sans-serif;
  position: relative;
}

.footer-link:before {
  content: "";
  visibility: hidden;
  background-color: #b8b8c7;
  width: 0%;
  height: 1px;
  transition: all .2s cubic-bezier(.33, 1, .68, 1);
  position: absolute;
  bottom: 1px;
  left: 0%;
}

.footer-link:hover:before, .footer-link:focus:before {
  visibility: visible;
  width: 100%;
  left: 0%;
  right: 50%;
}

.footer-links {
  color: #b8b8c7;
  letter-spacing: -.01em;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font: 400 12px Barlow, sans-serif;
  display: flex;
}

.socials {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  gap: 4px;
  margin-bottom: -4px;
  display: flex;
}

.socials-main {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
}

.socials-extra {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  display: flex;
}

.socials-main-link {
  background-color: #212128;
  border-radius: 9999px;
  outline: 1px solid #515167;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 3px 8px;
  transition: background-color .3s cubic-bezier(.33, 1, .68, 1);
  display: flex;
}

.socials-main-link:hover, .socials-main-link:focus {
  background-color: #515167;
}

.socials-main-link svg {
  fill: #b8b8c7;
  width: 14px;
}

.socials-main-link span {
  color: #b8b8c7;
  letter-spacing: -.01em;
  margin-top: -1px;
  font: 400 12px Barlow, sans-serif;
}

.socials-main-link:first-child svg {
  fill: #695eee;
}

.socials-main-link:nth-child(2) svg {
  fill: #22c0ff;
}

.socials-main-link:nth-child(3) svg {
  fill: #ff1744;
}

.socials-extra-link {
  fill: #b8b8c7;
  padding: 10px;
}

.socials-extra-link svg {
  width: 24px;
}

.socials-extra-link:first-child svg {
  transition: fill .3s cubic-bezier(.33, 1, .68, 1);
}

.socials-extra-link:first-child:hover svg, .socials-extra-link:first-child:focus svg {
  fill: #695eee;
}

.socials-extra-link:nth-child(2) svg {
  transition: fill .3s cubic-bezier(.33, 1, .68, 1);
}

.socials-extra-link:nth-child(2):hover svg, .socials-extra-link:nth-child(2):focus svg {
  fill: #2899cd;
}

.socials-extra-link:nth-child(3) svg {
  transition: fill .3s cubic-bezier(.33, 1, .68, 1);
}

.socials-extra-link:nth-child(3):hover svg, .socials-extra-link:nth-child(3):focus svg {
  fill: #58a1a4;
}

.socials-extra-link:nth-child(4) svg {
  transition: fill .3s cubic-bezier(.33, 1, .68, 1);
}

.socials-extra-link:nth-child(4):hover svg, .socials-extra-link:nth-child(4):focus svg {
  fill: #ff4e21;
}

.socials-extra-link:nth-child(5) svg {
  transition: fill .3s cubic-bezier(.33, 1, .68, 1);
}

.socials-extra-link:nth-child(5):hover svg, .socials-extra-link:nth-child(5):focus svg {
  fill: #9146ff;
}

.socials-extra-link:nth-child(6) svg {
  transition: fill .3s cubic-bezier(.33, 1, .68, 1);
}

.socials-extra-link:nth-child(6):hover svg, .socials-extra-link:nth-child(6):focus svg {
  fill: #1d9bf0;
}

.socials-extra-link:nth-child(7) svg {
  transition: fill .3s cubic-bezier(.33, 1, .68, 1);
}

.socials-extra-link:nth-child(7):hover svg, .socials-extra-link:nth-child(7):focus svg {
  fill: #695eee;
}

.copyright {
  color: #515167;
  letter-spacing: -.01em;
  text-align: center;
  margin-bottom: 0;
  font: 400 14px Barlow, sans-serif;
}

.header {
  background-color: #1a1a1f;
  outline: 0 solid #855ee8;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  padding: 24px;
  transition: outline .3s cubic-bezier(.33, 1, .68, 1);
  display: flex;
}

.header-outline {
  outline: 4px solid #855ee8;
}

@media screen and (width >= 768px) {
  .header-outline {
    outline: 0 solid #855ee8;
    transition: none;
  }
}

.logo {
  margin: -8px;
  padding: 8px;
  display: inline-block;
}

.logo-small svg {
  width: 44px;
  margin-bottom: -4px;
}

.logo-full {
  display: none;
}

.logo-full svg {
  width: 200px;
  margin-bottom: -4px;
}

.nav {
  justify-content: center;
  align-items: stretch;
  gap: 0;
  align-items: space-between;
  backdrop-filter: blur(4px);
  z-index: -1;
  background-color: #1a1a1ff7;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  transition: top .3s cubic-bezier(.33, 1, .68, 1);
  display: flex;
  position: absolute;
  top: calc(94px - 100dvh);
  right: 0;
}

@media screen and (width >= 768px) {
  .nav {
    backdrop-filter: none;
    z-index: 0;
    background-color: #0000;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    gap: 0;
    margin-left: 40px;
    display: flex;
    position: static;
  }
}

.nav-link {
  color: #f2f3f5;
  letter-spacing: -.02em;
  padding: calc(7.14286dvh - 18.7143px) 24px;
  font: bold 20px Inter Display, sans-serif;
  transition: background-color .3s cubic-bezier(.33, 1, .68, 1);
}

.nav-link:hover, .nav-link:focus {
  background-color: #51516733;
}

@media screen and (width >= 768px) {
  .nav-link {
    padding: 10px 8px;
    font-size: 18px;
    transition: background-color .3s cubic-bezier(.33, 1, .68, 1);
    position: relative;
  }

  .nav-link:hover, .nav-link:focus {
    background-color: #0000;
  }

  .nav-link:before {
    content: "";
    visibility: hidden;
    background-color: #855ee8;
    width: 0%;
    height: 2px;
    transition: all .2s cubic-bezier(.33, 1, .68, 1);
    position: absolute;
    bottom: 9px;
    left: 8px;
  }

  .nav-link:hover:before, .nav-link:focus:before {
    visibility: visible;
    width: calc(100% - 16px);
    left: 8px;
    right: 50%;
  }
}

.nav-dropdown {
  display: none;
}

.nav-active {
  top: 98px;
}

.nav-toggle {
  cursor: pointer;
  margin: -8px -12px -8px -8px;
  padding: 8px;
  transition: margin .3s cubic-bezier(.33, 1, .68, 1);
}

.nav-toggle svg {
  stroke: #b8b8c7;
  width: 40px;
  margin-bottom: -6px;
  transition: width .3s cubic-bezier(.33, 1, .68, 1);
}

@media screen and (width >= 768px) {
  .nav-toggle {
    display: none;
  }
}

.enlarge {
  margin: -8px -15px -8px -8px;
}

.enlarge svg {
  width: 48px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #fff2;
  background-clip: padding-box;
  border: 4px solid #0000;
  border-radius: 99px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #fff6;
}

.tooltip, .tooltip-svg {
  border-bottom: 1px dotted #b8b8c7;
  position: relative;
}

.tooltip-text, .tooltip-svg-text {
  color: #1a1a1f;
  letter-spacing: -.01em;
  opacity: 0;
  text-align: center;
  z-index: 3;
  background-color: #f2f3f5;
  border-radius: 4px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  width: 156px;
  margin-left: -78px;
  padding: 4px 6px;
  font: bold 12px Barlow, sans-serif;
  transition: opacity .3s cubic-bezier(.33, 1, .68, 1);
  display: flex;
  position: absolute;
  bottom: 24px;
  left: 50%;
  box-shadow: 0 0 5px 1px #000;
}

.tooltip-text:after, .tooltip-svg-text:after {
  content: "";
  border: 5px solid #0000;
  border-top-color: #f2f3f5;
  margin-left: -5px;
  position: absolute;
  top: 100%;
  left: 50%;
}

.tooltip:hover .tooltip-text, .tooltip-svg:hover .tooltip-text, .tooltip:active .tooltip-text, .tooltip-svg:active .tooltip-text {
  opacity: 1;
}

.tooltip-svg, .tooltip-svg-svg {
  border-bottom: none;
}

.tooltip-svg-text, .tooltip-svg-svg-text {
  width: 72px;
  margin-left: -36px;
  bottom: 42px;
}

.tooltip-svg:hover .tooltip-svg-text, .tooltip-svg-svg:hover .tooltip-svg-text, .tooltip-svg:active .tooltip-svg-text, .tooltip-svg-svg:active .tooltip-svg-text {
  opacity: 1;
}

.comms {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  gap: 0;
  margin-bottom: 32px;
  display: flex;
}

@media screen and (width >= 768px) {
  .comms {
    flex-flow: row;
    justify-content: center;
    align-items: stretch;
    gap: 0;
    display: flex;
  }
}

.comms-item {
  -webkit-user-select: none;
  user-select: none;
}

.comms-item source, .comms-item img {
  width: 100%;
}

.gallery {
  margin-bottom: 32px;
}

.gallery-object {
  border-radius: 4px;
  max-width: calc(50% - 4px);
  line-height: 0;
  transition: filter .3s cubic-bezier(.33, 1, .68, 1);
  display: block;
  position: relative;
}

.gallery-object:hover, .gallery-object:focus {
  filter: brightness(115%) saturate(115%);
}

@media screen and (width >= 500px) {
  .gallery-object {
    max-width: calc(33% - 4px);
  }
}

.gallery-inner {
  width: 100%;
  height: 100%;
}

.gallery-image source, .gallery-image img {
  border-radius: 4px;
  width: 100%;
}

.gallery-info {
  backdrop-filter: blur(3px);
  text-align: center;
  pointer-events: none;
  opacity: 0;
  background-color: #000000bf;
  border: 1px solid #b8b8c780;
  border-radius: 3px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  width: 100%;
  height: 100%;
  padding: 8px;
  transition: opacity .3s cubic-bezier(.33, 1, .68, 1);
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-object:hover .gallery-info {
  opacity: 1;
}

.gallery-title {
  color: #b8b8c7;
  letter-spacing: -.01em;
  font: 400 14px Barlow, sans-serif;
}

@media screen and (width >= 768px) {
  .gallery-title {
    font-size: 16px;
  }
}

.gallery-desc {
  color: #b8b8c7;
  letter-spacing: -.01em;
  font: 400 10px Barlow, sans-serif;
}

@media screen and (width >= 768px) {
  .gallery-desc {
    font-size: 12px;
  }
}

.intro {
  white-space-collapsing: discard;
  text-align: center;
  margin-bottom: 36px;
}

.showcase, .showcase-left, .showcase-right, .showcase-left-right {
  margin-bottom: 4px;
  transition: filter .3s cubic-bezier(.33, 1, .68, 1);
  position: relative;
  overflow: hidden;
}

.showcase:hover, .showcase-left:hover, .showcase-right:hover, .showcase-left-right:hover, .showcase:focus, .showcase-left:focus, .showcase-right:focus, .showcase-left-right:focus {
  filter: brightness(115%) saturate(115%);
}

@media screen and (width >= 500px) {
  .showcase, .showcase-left, .showcase-right, .showcase-left-right {
    min-width: 46vw;
  }
}

@media screen and (width >= 768px) {
  .showcase, .showcase-left, .showcase-right, .showcase-left-right {
    min-width: 56vw;
  }
}

@media screen and (width >= 928px) {
  .showcase, .showcase-left, .showcase-right, .showcase-left-right {
    min-width: 60%;
  }
}

.showcase-container, .showcase-left-container, .showcase-right-container, .showcase-left-right-container, .showcase-left-left-container, .showcase-right-right-container, .showcase-left-right-right-container {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  gap: 0;
  margin-bottom: 64px;
  display: flex;
  position: relative;
}

@media screen and (width >= 500px) {
  .showcase-container, .showcase-left-container, .showcase-right-container, .showcase-left-right-container, .showcase-left-left-container, .showcase-right-right-container, .showcase-left-right-right-container {
    margin-bottom: 88px;
    line-height: 0;
  }
}

.showcase-desc, .showcase-left-desc, .showcase-right-desc, .showcase-left-right-desc, .showcase-left-left-desc, .showcase-right-right-desc, .showcase-left-right-right-desc {
  color: #f2f3f5;
  letter-spacing: -.02em;
  font: bold 30px / 30px Inter Display, sans-serif;
}

@media screen and (width >= 500px) {
  .showcase-desc, .showcase-left-desc, .showcase-right-desc, .showcase-left-right-desc, .showcase-left-left-desc, .showcase-right-right-desc, .showcase-left-right-right-desc {
    color: #f2f3f5;
    letter-spacing: -.02em;
    margin-bottom: 0;
    font: bold 5vw / 5vw Inter Display, sans-serif;
  }
}

@media screen and (width >= 928px) {
  .showcase-desc, .showcase-left-desc, .showcase-right-desc, .showcase-left-right-desc, .showcase-left-left-desc, .showcase-right-right-desc, .showcase-left-right-right-desc {
    color: #f2f3f5;
    letter-spacing: -.02em;
    font: bold 44px / 44px Inter Display, sans-serif;
  }
}

.showcase-detail, .showcase-left-detail, .showcase-right-detail, .showcase-left-right-detail, .showcase-left-left-detail, .showcase-right-right-detail, .showcase-left-right-right-detail {
  display: inline;
}

@media screen and (width >= 500px) {
  .showcase-detail, .showcase-left-detail, .showcase-right-detail, .showcase-left-right-detail, .showcase-left-left-detail, .showcase-right-right-detail, .showcase-left-right-right-detail {
    position: absolute;
    bottom: -48px;
    right: 0;
  }
}

.showcase-title, .showcase-left-title, .showcase-right-title, .showcase-left-right-title {
  color: #f2f3f5;
  letter-spacing: -.05em;
  margin-bottom: -4px;
  font: 26px Axis, sans-serif;
}

.showcase-role, .showcase-left-role, .showcase-right-role, .showcase-left-right-role {
  color: auto;
  letter-spacing: -.05em;
  margin-bottom: 0;
  font: 18px Axis, sans-serif;
}

.showcase-inner, .showcase-left-inner, .showcase-right-inner, .showcase-left-right-inner {
  line-height: 0;
}

.showcase-image, .showcase-left-image, .showcase-right-image, .showcase-left-right-image {
  -webkit-user-select: none;
  user-select: none;
}

.showcase-image source, .showcase-left-image source, .showcase-right-image source, .showcase-left-right-image source, .showcase-image img, .showcase-left-image img, .showcase-right-image img, .showcase-left-right-image img {
  width: calc(120vw - 48px);
  height: 100%;
  position: relative;
}

@media screen and (width >= 500px) {
  .showcase-image source, .showcase-left-image source, .showcase-right-image source, .showcase-left-right-image source, .showcase-image img, .showcase-left-image img, .showcase-right-image img, .showcase-left-right-image img {
    width: 100%;
  }
}

.showcase-shape, .showcase-left-shape, .showcase-right-shape, .showcase-left-right-shape, .showcase-left-left-shape, .showcase-right-right-shape, .showcase-left-right-right-shape {
  z-index: -1;
  width: 90vw;
  position: absolute;
}

@media screen and (width >= 500px) {
  .showcase-shape, .showcase-left-shape, .showcase-right-shape, .showcase-left-right-shape, .showcase-left-left-shape, .showcase-right-right-shape, .showcase-left-right-right-shape {
    width: 340px;
  }
}

@media screen and (width >= 768px) {
  .showcase-shape, .showcase-left-shape, .showcase-right-shape, .showcase-left-right-shape, .showcase-left-left-shape, .showcase-right-right-shape, .showcase-left-right-right-shape {
    width: 400px;
  }
}

.showcase-left, .showcase-left-left, .showcase-right-left, .showcase-left-right-left {
  border-radius: 0 4px 4px 0;
  left: -24px;
}

@media screen and (width >= 928px) {
  .showcase-left, .showcase-left-left, .showcase-right-left, .showcase-left-right-left {
    border-radius: 4px;
  }
}

@media screen and (width >= 500px) {
  .showcase-left-container, .showcase-left-left-container, .showcase-right-left-container, .showcase-left-right-left-container {
    flex-flow: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 0;
    display: flex;
  }
}

.showcase-left-desc, .showcase-left-left-desc, .showcase-right-left-desc, .showcase-left-right-left-desc {
  text-align: right;
}

.showcase-left-shape, .showcase-left-left-shape, .showcase-right-left-shape, .showcase-left-right-left-shape {
  left: -40vw;
}

@media screen and (width >= 500px) {
  .showcase-left-shape, .showcase-left-left-shape, .showcase-right-left-shape, .showcase-left-right-left-shape {
    left: -220px;
  }
}

@media screen and (width >= 928px) {
  .showcase-left-shape, .showcase-left-left-shape, .showcase-right-left-shape, .showcase-left-right-left-shape {
    left: -30px;
  }
}

.showcase-left-detail p, .showcase-left-left-detail p, .showcase-right-left-detail p, .showcase-left-right-left-detail p {
  text-align: left;
  margin-left: -12px;
}

@media screen and (width >= 500px) {
  .showcase-left-detail, .showcase-left-left-detail, .showcase-right-left-detail, .showcase-left-right-left-detail {
    left: 8px;
  }
}

.showcase-left .showcase-title, .showcase-left-left .showcase-title, .showcase-right-left .showcase-title, .showcase-left-right-left .showcase-title, .showcase-left .showcase-role, .showcase-left-left .showcase-role, .showcase-right-left .showcase-role, .showcase-left-right-left .showcase-role {
  text-align: right;
}

.showcase-right, .showcase-left-right, .showcase-right-right, .showcase-left-right-right {
  border-radius: 4px 0 0 4px;
  right: calc(48px - 20vw);
}

@media screen and (width >= 500px) {
  .showcase-right, .showcase-left-right, .showcase-right-right, .showcase-left-right-right {
    right: -24px;
  }
}

@media screen and (width >= 928px) {
  .showcase-right, .showcase-left-right, .showcase-right-right, .showcase-left-right-right {
    border-radius: 4px;
  }
}

.showcase-right source, .showcase-left-right source, .showcase-right-right source, .showcase-left-right-right source, .showcase-right img, .showcase-left-right img, .showcase-right-right img, .showcase-left-right-right img {
  right: 20vw;
}

@media screen and (width >= 500px) {
  .showcase-right source, .showcase-left-right source, .showcase-right-right source, .showcase-left-right-right source, .showcase-right img, .showcase-left-right img, .showcase-right-right img, .showcase-left-right-right img {
    right: 0;
  }

  .showcase-right-container, .showcase-left-right-container, .showcase-right-right-container, .showcase-left-right-right-container {
    flex-flow: row;
    justify-content: center;
    align-items: center;
    gap: 0;
    display: flex;
  }
}

.showcase-right-desc, .showcase-left-right-desc, .showcase-right-right-desc, .showcase-left-right-right-desc {
  text-align: left;
}

.showcase-right-shape, .showcase-left-right-shape, .showcase-right-right-shape, .showcase-left-right-right-shape {
  right: -40vw;
}

@media screen and (width >= 500px) {
  .showcase-right-shape, .showcase-left-right-shape, .showcase-right-right-shape, .showcase-left-right-right-shape {
    right: -220px;
  }
}

@media screen and (width >= 928px) {
  .showcase-right-shape, .showcase-left-right-shape, .showcase-right-right-shape, .showcase-left-right-right-shape {
    right: -30px;
  }
}

.showcase-right-detail p, .showcase-left-right-detail p, .showcase-right-right-detail p, .showcase-left-right-right-detail p {
  text-align: right;
  margin-right: -12px;
}

@media screen and (width >= 500px) {
  .showcase-right-detail, .showcase-left-right-detail, .showcase-right-right-detail, .showcase-left-right-right-detail {
    right: 8px;
  }
}

.showcase-right .showcase-title, .showcase-left-right .showcase-title, .showcase-right-right .showcase-title, .showcase-left-right-right .showcase-title, .showcase-right .showcase-role, .showcase-left-right .showcase-role, .showcase-right-right .showcase-role, .showcase-left-right-right .showcase-role {
  text-align: left;
}

.showcase-blue, .showcase-left-blue, .showcase-right-blue, .showcase-left-right-blue {
  background: #60a5fa;
}

.showcase-orange, .showcase-left-orange, .showcase-right-orange, .showcase-left-right-orange {
  background: #ffb86c;
}

.showcase-pink, .showcase-left-pink, .showcase-right-pink, .showcase-left-right-pink {
  background: #c084fc;
}

.showcase-cyan, .showcase-left-cyan, .showcase-right-cyan, .showcase-left-right-cyan {
  background: #67e8f9;
}

.itg {
  flex-flow: wrap;
  justify-content: start;
  align-items: stretch;
  gap: 8px;
  margin-bottom: 16px;
  display: flex;
}

.itg-main-inner {
  border-radius: 4px;
  width: calc(50% - 4px);
  height: 100%;
  transition: filter .3s cubic-bezier(.33, 1, .68, 1);
}

.itg-main-inner:hover, .itg-main-inner:focus {
  filter: brightness(115%) saturate(115%);
}

@media screen and (width >= 500px) {
  .itg-main-inner {
    width: calc(33% - 4px);
  }
}

.itg-main-image source, .itg-main-image img {
  border-radius: 4px;
  width: 100%;
}

.portfolio-main {
  flex-flow: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 8px;
  display: flex;
}

.portfolio-main-outer {
  width: calc(50% - 4px);
  position: relative;
}

@media screen and (width >= 500px) {
  .portfolio-main-outer {
    width: calc(25% - 6px);
  }
}

.portfolio-main-text {
  color: #f2f3f5;
  letter-spacing: -.02em;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  flex-flow: wrap;
  justify-content: left;
  align-items: end;
  gap: 0;
  width: 100%;
  height: 100%;
  padding: 12px 8px;
  font: bold 8vw Inter Display, sans-serif;
  display: flex;
  position: absolute;
}

.portfolio-main-text span {
  text-shadow: 0 0 4px #000;
  line-height: 8.2vw;
}

@media screen and (width >= 500px) {
  .portfolio-main-text {
    font-size: 5vw;
  }

  .portfolio-main-text span {
    line-height: 5.2vw;
  }
}

@media screen and (width >= 928px) {
  .portfolio-main-text {
    font-size: 44px;
  }

  .portfolio-main-text span {
    line-height: 44px;
  }
}

.portfolio-main-inner {
  height: 100%;
  transition: filter .3s cubic-bezier(.33, 1, .68, 1);
}

.portfolio-main-inner:hover, .portfolio-main-inner:focus {
  filter: brightness(160%) saturate(120%);
}

.portfolio-main-image source, .portfolio-main-image img {
  border-radius: 4px;
  width: 100%;
}

.portfolio-nav {
  flex-flow: row;
  justify-content: space-evenly;
  align-items: stretch;
  gap: 0;
  display: flex;
}

.portfolio-nav a {
  color: #b8b8c7;
  letter-spacing: -.01em;
  border-radius: 4px;
  padding: 4px 16px;
  font: 600 20px Barlow, sans-serif;
}

.portfolio-lang {
  flex-flow: row;
  justify-content: left;
  align-items: stretch;
  gap: 16px;
  margin-bottom: 16px;
  display: flex;
}

.portfolio-lang svg, .portfolio-lang source, .portfolio-lang img {
  width: 32px;
}

.portfolio-actions {
  flex-flow: wrap;
  justify-content: left;
  align-items: stretch;
  gap: 16px;
  margin-bottom: 16px;
  display: flex;
}

.portfolio-outer {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  gap: 0;
  margin-bottom: 32px;
  display: flex;
}

@media screen and (width >= 768px) {
  .portfolio-outer {
    flex-flow: row;
    justify-content: center;
    align-items: start;
    gap: 16px;
    display: flex;
  }
}

.portfolio-embed {
  width: 100%;
  margin-bottom: 16px;
}

@media screen and (width >= 768px) {
  .portfolio-embed {
    min-width: 55%;
  }
}

.portfolio-embed source, .portfolio-embed img {
  border-radius: 4px;
  width: 100%;
}

.portfolio-inner li {
  margin-bottom: 6px;
  line-height: 16px;
}

.feature {
  background-color: #212128;
  border-left: 4px solid #855ee8;
  border-radius: 4px;
  width: 100%;
  padding: 8px 12px;
  line-height: 16px;
}

.resume {
  flex-flow: column-reverse;
  justify-content: center;
  align-items: stretch;
  gap: 0;
  margin-top: 32px;
  display: flex;
}

@media screen and (width >= 768px) {
  .resume {
    flex-flow: row;
    justify-content: center;
    align-items: stretch;
    gap: 24px;
    display: flex;
  }
}

.resume-top {
  flex-flow: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

@media screen and (width >= 500px) {
  .resume-top {
    flex-flow: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 12px;
    display: flex;
  }
}

@media screen and (width >= 768px) {
  .resume-top {
    gap: 24px;
  }
}

.resume-top-links {
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
  gap: 8px;
  display: flex;
}

.resume-h1 {
  color: #f2f3f5;
  letter-spacing: -.01em;
  text-align: center;
  font: 600 6.47vw Inter Display, sans-serif;
  margin-bottom: -2px !important;
}

@media screen and (width >= 500px) {
  .resume-h1 {
    text-align: left;
    font-size: 6.1vw;
  }
}

@media screen and (width >= 928px) {
  .resume-h1 {
    font-size: 56px;
  }
}

.resume-sub {
  color: #b8b8c7;
  letter-spacing: -.01em;
  text-align: center;
  font: italic 600 3.4vw Barlow, sans-serif;
}

@media screen and (width >= 500px) {
  .resume-sub {
    font-size: 2.6vw;
  }
}

@media screen and (width >= 928px) {
  .resume-sub {
    font-size: 24px;
  }
}

.resume-left {
  position: relative;
}

@media screen and (width >= 768px) {
  .resume-left {
    text-align: right;
    flex-flow: column;
    justify-content: stretch;
    align-items: stretch;
    gap: 0;
    min-width: 176px;
    max-width: 176px;
    display: flex;
  }
}

.resume-left-group {
  flex-flow: wrap;
  justify-content: start;
  align-items: start;
  gap: 8px;
  margin-bottom: 16px;
  display: flex;
}

.resume-left-container {
  min-width: calc(50% - 8px);
  max-width: calc(50% - 8px);
}

@media screen and (width >= 768px) {
  .resume-left-container {
    background-color: #212128;
    border-radius: 4px;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 8px;
    padding: 8px;
  }
}

.resume-left-container p {
  margin-bottom: 0;
  line-height: 16px;
  text-align: left !important;
}

@media screen and (width >= 768px) {
  .resume-left-container p {
    text-align: right !important;
  }
}

@media screen and (width >= 928px) {
  .resume-right {
    border-left: 2px solid #c084fc;
    padding-left: 32px;
  }
}

.resume-right-date {
  text-align: left;
  margin-bottom: -4px;
}

@media screen and (width >= 500px) {
  .resume-right-date {
    text-align: left;
    min-width: 80px;
    max-width: 80px;
    margin-top: 6px;
    line-height: 10px !important;
  }
}

.resume-right-container {
  background-color: #212128;
  border-left: 4px solid #c084fc;
  border-radius: 4px;
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  gap: 0;
  margin-bottom: 16px;
  padding: 12px 16px;
  display: flex;
}

@media screen and (width >= 500px) {
  .resume-right-container {
    flex-flow: row;
    justify-content: left;
    align-items: stretch;
    gap: 8px;
    display: flex;
  }
}

.resume-right-container-inner p, .resume-right-container-inner li {
  margin-bottom: 6px;
  line-height: 16px;
}

.resume-last h3 {
  text-align: center;
  margin-bottom: 0;
}

@media screen and (width >= 768px) {
  .resume-last h3 {
    text-align: right;
  }
}

.resume-last p {
  text-align: center;
  line-height: 16px;
}

@media screen and (width >= 768px) {
  .resume-last p {
    text-align: right;
  }

  .resume-last {
    margin-top: auto;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #1a1a1f;
  max-width: 928px;
  margin: 0 auto;
}

main {
  min-height: calc(100dvh - 258px);
  margin: 0 auto;
  padding: 8px 24px;
  overflow-x: clip;
}

@media screen and (width >= 928px) {
  main {
    overflow-x: visible;
  }
}

h1 {
  color: #f2f3f5;
  letter-spacing: -.02em;
  margin-bottom: 16px;
  font: bold 64px Inter Display, sans-serif;
}

.h1-small {
  color: #f2f3f5;
  letter-spacing: -.02em;
  margin-bottom: 16px;
  font: bold 12.8vw Inter Display, sans-serif;
}

@media screen and (width >= 500px) {
  .h1-small {
    color: #f2f3f5;
    letter-spacing: -.02em;
    font: bold 64px Inter Display, sans-serif;
  }
}

h2 {
  color: #f2f3f5;
  letter-spacing: -.02em;
  margin-bottom: 12px;
  font: bold 42px Inter Display, sans-serif;
}

h3 {
  color: #f2f3f5;
  letter-spacing: -.02em;
  margin-bottom: 4px;
  font: bold 28px Inter Display, sans-serif;
}

.hsub {
  color: #b8b8c7;
  letter-spacing: -.05em;
  font: 22px Axis, sans-serif;
}

p, li {
  color: #b8b8c7;
  letter-spacing: -.01em;
  text-align: justify;
  margin-bottom: 16px;
  font: 400 16px / 20px Barlow, sans-serif;
}

ul {
  list-style-position: inside;
}

a {
  color: #60a5fa;
  font-weight: 600;
}

b {
  color: #f2f3f5;
  font-weight: 600;
}

i {
  font-style: italic;
}

hr {
  background-color: #515167;
  border: 0;
  width: 200px;
  height: 1px;
  margin: 32px auto;
}

.comment, .comment-small {
  color: #858597;
}

.comment-small, .comment-small-small {
  font-size: 14px;
  line-height: 16px;
}

.no-bold {
  font-weight: 400;
}

.diff {
  text-align: center;
  color: #f2f3f5;
  letter-spacing: -.01em;
  background-color: #515167;
  border-radius: 4px;
  margin-left: 4px;
  padding: 2px 4px;
  font: 600 14px Barlow, sans-serif;
  display: inline-block;
  position: relative;
  top: -8px;
}

.action {
  text-align: center;
  color: #f2f3f5;
  letter-spacing: -.01em;
  background-color: #855ee8;
  border-radius: 4px;
  padding: 4px 12px;
  font: 600 14px Inter Display, sans-serif;
  transition: filter .3s cubic-bezier(.33, 1, .68, 1);
  display: inline-block;
}

.action:hover, .action:focus {
  filter: brightness(115%) saturate(115%);
}

@media screen and (width >= 768px) {
  .action {
    font-size: 16px;
  }
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.num {
  text-align: center;
  color: #b8b8c7;
  letter-spacing: -.01em;
  background-color: #855ee8;
  border-radius: 4px;
  width: 32px;
  margin-right: 4px;
  padding: 2px 4px;
  font: 600 14px Barlow, sans-serif;
  display: inline-block;
}

.link, .link-epic {
  position: relative;
}

.link:before, .link-epic:before {
  content: "";
  visibility: hidden;
  background-color: #60a5fa;
  width: 0%;
  height: 1px;
  transition: all .2s cubic-bezier(.33, 1, .68, 1);
  position: absolute;
  bottom: 1px;
  left: 0%;
}

.link:hover:before, .link-epic:hover:before, .link:focus:before, .link-epic:focus:before {
  visibility: visible;
  width: 100%;
  left: 0%;
  right: 50%;
}

.external:after {
  content: "🡥";
  letter-spacing: 0;
  margin-left: 3px;
  font-size: .6em;
  position: relative;
  top: -1px;
}

.extend-small {
  margin-bottom: 8px;
}

.extend-none {
  margin-bottom: 0;
}

.container {
  margin-bottom: 32px;
}

.crunch {
  margin-bottom: 2px !important;
  line-height: 10px !important;
}

.thin {
  border-radius: 4px;
  width: 100%;
  max-width: 820px;
}

.link, .link-epic {
  text-align: justify;
}

.link-epic, .link-epic-epic {
  animation: 2s infinite awes;
}

.sig {
  text-align: right;
  margin-top: 4px;
}

.sig svg {
  width: 225px;
}

.freeze {
  overflow-y: hidden;
}

@media screen and (width >= 768px) {
  .freeze {
    overflow-y: auto;
  }
}

.lowered {
  z-index: -2;
  position: relative;
}

.no-zindex {
  z-index: 1;
}

.column {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  gap: 16px;
  display: flex;
}

@keyframes awes {
  0% {
    filter: hue-rotate();
  }

  50% {
    filter: hue-rotate(100deg);
  }

  100% {
    filter: hue-rotate();
  }
}
/*# sourceMappingURL=about.b542c0b8.css.map */
